.linksHome_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: 150rem;
  margin: 0 auto;
  background-color: #1b0000;
  height: 15rem;

  a {
    font-size: 2rem;
    text-decoration: none;
    display: flex;
    align-items: start;
    justify-content: start;
    max-width: 140rem;
    margin: 0 auto;
    color: #edf2f4;

    &:hover,
    &:active {
      color: #ef233c;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 10px;

    a {
      margin-bottom: 10px;
    }

    .linksHome_line {
      width: 90%;
    height: 1px;
    background-color: #c80200;
    }
  }
}
