.OneCard_container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100rem;
  margin: 0rem auto;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;

  .OneCard_itemBox {
    a {
      font-size: 2.4rem;
      text-decoration: none;
      color: #c80200;
      border: 1px solid #ced4da;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 50px;
      text-decoration: none;
      width: 30rem;
      height: 15rem;
      margin-bottom: 3rem;

      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;

      &:hover,
      &:active {
        color: #ef233c;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
      }
    }
  }

  @media (max-width: 768px) {
    .OneCard_container {
      align-items: center;
      justify-content: center;
    }
    .OneCard_itemBox {
      width: 100%;
      margin-bottom: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
