.ExerciseKanji_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .ExerciseKanji_img {
    margin: 3rem auto;
    width: 45rem;
  }

  .ExerciseKanji_line {
    width: 70%;
    height: 1px;
    margin-top: 2rem;
    background-color: #c80200;
  }

  .ExerciseKanji_h2 {
    font-size: 2.4rem;
  }

  .ExerciseKanji_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 110rem;
    margin: 5rem auto 0 auto;
    justify-content: center;

    p {
      font-size: 3.6rem;
      margin-top: 2rem;
      margin-bottom: 0rem;
      color: #c80200;
    }

    input {
      max-width: 16rem;
      margin: 2rem 0;
      font-size: 2.4rem;

      &:focus {
        outline-color: #c80200;
      }
    }

    h4 {
      font-size: 1.6rem;
      margin: 1rem 1rem 0 1rem;
      color: #6c757d;

      &:hover,
      &:active {
        cursor: pointer;
        color: #c80200;
      }
    }

    .ExerciseKanji_answers {
      margin-top: 1rem;
      height: 12rem;
      cursor: pointer;
    }

    .ExerciseKanji_answers_h4 {
color: #2B2D42;
    }

    .inputDefault {
      border: 1px solid #ced4da;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 28rem;
      width: 20rem;
      text-decoration: none;
      margin-bottom: 3rem;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
    }

    .inputOk {
      border: 2px solid #01750d;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 28rem;
      width: 20rem;
      text-decoration: none;
      margin-bottom: 3rem;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;

      input {
        border-color: #01750d;
        &:focus {
          outline-color: #01750d;
        }
      }
    }

    .inputMistake {
      border: 2px solid #c80200;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 28rem;
      width: 20rem;
      text-decoration: none;
      margin-bottom: 3rem;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;

      input {
        &:focus {
          outline-color: #c80200;
        }
      }
    }
  }

  .ExerciseKanji_newlist_buttom {
    font-size: 2.4rem;
    color: #c80200;
    text-decoration: none;
    display: flex;
    align-items: center;
    flex-direction: row;

    &:hover,
    &:active {
      color: #ef233c;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .ExerciseKanji_container {
    align-items: center;
    justify-content: center;
    max-width: 90%;
    margin: 0 auto;
  }

  .ExerciseKanji_img {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .ExerciseKanji_box {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}
