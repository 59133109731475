.grammarList_container {
  display: flex;
  flex-direction: column;
  max-width: 120rem;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  .grammarList_thead {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #c80200;
      border-radius: 6px;
      width: 35rem;
      height: 8rem;
      margin: 0rem 2rem;
      background-color: #c80200;

      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
      font-size: 2.4rem;
      color: #fff;
    }
  }

  .grammarList_tbody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid #ced4da;
      border-radius: 8px;
      width: 35rem;
      height: 10rem;
      text-align: center;
      margin: 0.5rem 2rem;

      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
      font-size: 2.4rem;
      color: #2b2d42;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      h5 {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ced4da;
        border-radius: 8px;
        width: 35rem;
        height: 10rem;
        text-align: center;
        margin: 0.5rem 2rem;

        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
        font-size: 2.4rem;
        color: #c80200;

        &:hover,
        &:active {
          color: #ef233c;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
        }
      }
    }
  }

  .grammarList_memo {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.6rem;
      color: #2b2d42;
      width: 100rem;
    }
  }

  @media (max-width: 768px) {
    .grammarList_container {
      max-width: 100%;
      width: 100%;
    }

    .grammarList_thead {
      p {
        width: 12.5rem;
        font-size: 1.6rem;
        margin: 0 0.1rem;
      }
    }
    .grammarList_tbody {
      p {
        width: 12.5rem;
        font-size: 1.6rem;
        margin: 0 0.1rem;
        height: 9rem;
      }
      a {
        h5 {
          width: 12.5rem;
          font-size: 1.6rem;
          margin: 0 0.1rem;
          height: 9rem;
        }
      }
    }

    .grammarList_mobileOff {
      display: none;
    }

    .grammarList_memo {
      width: 85%;
      text-align: center;
      margin: 0 auto;
    }
  }
}
