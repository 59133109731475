.detalhesCaracter_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  max-width: 100rem;
  margin: 3rem auto;
  text-decoration: none;

  h1 {
    font-size: 3.6rem;
  }

  .caracter_box {
    border: 1px solid #ced4da;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20rem;
    width: 20rem;
    text-decoration: none;
    margin-bottom: 3rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;

    h3 {
      font-size: 4rem;
      margin-bottom: 3px;
      color: #1b0000;
    }

    h2 {
      font-size: 6rem;
      margin-top: 0;
      color: #c80200;
    }
  }

  .caracter_exemplo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 2.4rem;
    margin-bottom: 0;
    width: 100%;
  }

  .caracter_exemplo_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-decoration: none;
    width: 100%;

    p {
      font-size: 2rem;
      margin-bottom: 0;
      color: #1b0000;
    }

    .caracter_kana {
      font-size: 2rem;
      margin-bottom: 0;
      color: #1b0000;
    }

    .caracter_traducao {
      width: 35rem;
      padding-bottom: 3rem;
      border-bottom: 1px solid #c80200;
    }
  }

  .detalhesCaracter_next_link {
    font-size: 2.4rem;
    color: #c80200;
    text-decoration: none;
    display: flex;
      align-items: center;
      flex-direction: row;

      &:hover,
      &:active {
        color: #ef233c;
      }
  }
}

@media (max-width: 768px) {
  .detalhesCaracter_container {
    .caracter_exemplo {
      width: 85%;
    }

    .caracter_exemplo_box {
       width: 85%;
    }
  }
}