.header_container {
  background-color: #1b0000;
  position: sticky;
  top: 0;

  .header_link_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 130rem;
    margin: 0 auto;
    padding: 10px;

    a {
      text-decoration: none;
      color: #edf2f4;
      font-size: 1.6rem;
      transition: all 0.3s;
      display: inline-block;
      margin-left: 2.6rem;
    }

    img {
      width: 180px;
      margin: 0;
    }

    .header_links {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 80rem;

      a {
        width: 100%;
        margin-bottom: 5px;
      }
    }
  }
  @media (max-width: 768px) {
    .header_link_box {
      flex-direction: column;
      align-items: center;
      img {
        width: 200px;
        margin: 2rem;
      }
    }

    .header_link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;

      a {
        margin: 1rem;
      }
    }
  }
}
