.table_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  height: 40rem;
  max-width: 100rem;
  margin: 0 auto;

  .table_box {
    border: 1px solid #ced4da;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: calc(20% - 10px);
    height: 50px;
    text-decoration: none;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;

    p {
      font-size: 2rem;
      margin-bottom: 3px;
      color: #1b0000;
    }

    h2 {
      font-size: 2.4rem;
      margin-top: 0;
      color: #c80200;
    }

    &:hover,
    &:active {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
    }
  }

  .table_box_yoon {
    border: 1px solid #ced4da;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: calc(33% - 10px);
    text-decoration: none;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;

    p {
      font-size: 2rem;
      margin-bottom: 3px;
      color: #1b0000;
    }

    h2 {
      font-size: 2.4rem;
      margin-top: 0;
      color: #c80200;
    }

    &:hover,
    &:active {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
    }
  }
}

@for $i from 1 through 48 {
  .table_container .table_box:nth-child(#{$i}) {
    /* Estilos para todos os elementos */

    /* Estilos específicos para os elementos de índice 39 e 40 */
    @if $i == 39 or $i == 40 {
      border: none;
      box-shadow: none;
      pointer-events: none;
    }
  }
}

@media (max-width: 768px) {
  .table_container {
    width: 90%;
    .table_box {
      p {
        font-size: 1.6rem;
      }
      h2 {
font-size: 2rem;
      }
    }
.table_box_yoon {
  flex-basis: auto;
   p {
        font-size: 1.6rem;
      }
      h2 {
font-size: 2rem;
      }
}

  }
}
