.kanjiPage_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  max-width: 120rem;
  margin: 2rem auto;
  text-decoration: none;

  h1 {
    font-size: 3.6rem;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 2.4rem;
    margin: 0;
  }

  .kanjiPage_line {
    width: 120rem;
    height: 1px;
    background-color: #c80200;
  }

  .kanjiPage_box_kanji {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // align-self: flex-start;
  }

  .kanjiPage_top_box {
    display: flex;
    flex-direction: row;
    // align-items: flex-start;
    // justify-content: flex-start;
    gap: 10rem;
    margin-top: 2rem;
  }

  .kanjiPage_box {
    border: 1px solid #ced4da;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20rem;
    width: 20rem;
    text-decoration: none;
    margin-bottom: 2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;

    h2 {
      font-size: 6rem;
      margin: 0;
      color: #c80200;
    }

    h3 {
      font-size: 2.6rem;
      color: #1b0000;
      text-align: center;
      padding: 1rem;
    }
  }

  .kanjiPage_box_informations {
    border: 1px solid #bdbebf;

    background-color: #ced4da;
    color: #1b0000;
    border-radius: 8px;
    height: 16rem;
    padding: 2rem 3rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;

    h3 {
      margin-bottom: 1rem;
    }
  }

  .kanjiPage_informations {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    align-self: flex-start;
    border-top: 1px solid #1b0000;

    h4 {
      font-size: 1.8rem;
      margin: 2rem 0 0 0;
    }

    p {
      font-size: 1.8rem;
      margin: 0.5rem;
    }
  }

  .kanjiPage_informations_horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .kanjiPage_informations_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;
  }

  .kanjiPage_exemplos {
    display: flex;
    flex-direction: column;
    align-items: start;
    // justify-content: flex-start;
    // align-self: flex-start;
    margin: 2rem auto;

    h3 {
      margin-bottom: 2rem;
    }

    .kanjiPage_exemplos_block {
      width: 50rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      align-self: flex-start;
      border: 1px solid #bdbebf;
      padding: 2rem;
      margin: 1rem;
      border-radius: 8px;

      h4 {
        font-size: 1.8rem;
        margin: 0 0 1rem 0;
      }
      p {
        font-size: 1.8rem;
        margin: 0;
      }

      .kanjiPage_exemplos_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .kanjiPage_next {
    font-size: 2.4rem;
    color: #c80200;
    text-decoration: none;
    display: flex;
    align-items: center;
    flex-direction: row;

    &:hover,
    &:active {
      color: #ef233c;
    }
  }
  
  @media (max-width: 768px) {
    .kanjiPage_container {
      width: 90%;
    }

    .kanjiPage_line {
      width: 85%;
    }

    .kanjiPage_top_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: center;
      gap: 2rem;
      margin: 2rem auto;
    }

    .kanjiPage_box_informations {
      height: 40rem;
      width: 30rem;
    }

    .kanjiPage_informations {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .kanjiPage_exemplos {
      width: 90%;
      align-items: center;
    }

    .kanjiPage_exemplos_block {
      max-width: 85%;
      padding: 1rem;
      margin: 0;
      align-items: center;
      align-self: center;
    }
  }
}
