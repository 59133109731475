.TitleAndSubtitle_container {
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
  }

  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.2rem;
    margin-bottom: 0;
    margin-top: 5rem;
  }

  h6 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    margin-bottom: 0;
    margin-top: 5rem;
  }

  .hiragana_p {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  @media (max-width: 768px) {
    h3 {
      width: 90%;
      font-size: 2.4rem;
      margin: 3rem auto 0 auto;
      text-align: center;
    }

    p {
      width: 90%;
      text-align: center;
      margin: 3rem auto 0 auto;
    }

    .hiragana_p {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
    }

    h6{
      width: 90%;
      text-align: center;
      margin: 3rem auto 0 auto;
    }
  }
}
