.PreparingForJLPT_container {
  display: flex;
  align-items: center;
  max-width: 120rem;
  margin: 3rem auto;
  flex-direction: column;

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 5rem;
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  .PreparingForJLPT_box {
    margin-bottom: 3rem;
    max-width: 90rem;
  }

  @media (max-width: 768px) {
    max-width: 90%;

    .PreparingForJLPT_box {
      width: 100%;
    }
  }
}
