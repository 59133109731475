.firstSteps_container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 100rem;
  margin: 3rem auto;

  .firstSteps_box {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5rem;
    margin: 0 auto;

    .firstSteps_boxItem {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 auto;
    }

    img {
      width: 48rem;
      border-radius: 5px;
      height: 70rem;
      object-fit: cover;
    }

    a {
      font-size: 2.4rem;
      text-decoration: none;
      color: #c80200;
      border: 1px solid #ced4da;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 30rem;
      height: 15rem;
      margin-bottom: 3rem;

      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;

      &:hover,
      &:active {
        color: #ef233c;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
      }
    }

    h2 {
      font-size: 3rem;
      color: #8d99ae;
    }

    h1 {
      font-size: 3.2rem;
      color: #2b2d42;
    }

    .firstSteps_boximg {
      height: 140rem;
    }
  }

  @media (max-width: 768px) {

    .firstSteps_box {
      grid-template-columns: 1fr;
    }

    .firstSteps_boxItem {
      margin-bottom: 3rem;
    }

    img {
      display: none;
    }

    a {
      width: 100%;
    }
  }
}

.firstSteps_box2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 150rem;
  margin: 0 auto;

  img {
    max-width: 150rem;
  }

  .firstSteps_boxItem2 {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 auto;
  }

  .firstSteps_mobileArrow {
    display: none;
  }

  a {
    font-size: 2.4rem;
    text-decoration: none;
    color: #c80200;
    border: 1px solid #ced4da;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    text-decoration: none;
    width: 22rem;
    height: 15rem;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;

    &:hover,
    &:active {
      color: #ef233c;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
    }
  }

  h2 {
    font-size: 3rem;
    color: #8d99ae;
    margin: 0 2rem;
  }

  h1 {
    font-size: 3.2rem;
    color: #2b2d42;
    margin: 5rem;
  }

  @media (max-width: 768px) {
    .firstSteps_boxItem2 {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 3rem;
      width: 100%;
    }

    a {
      width: 30rem;
      height: 15rem;
      margin: 2rem;
    }

    img {
      width: 100%;
    }

    .firstSteps_mobileArrowRight {
      display: none;
    }

    .firstSteps_mobileArrow {
      display: block;
      h2 {
        font-size: 3rem;
        color: #8d99ae;
      }
    }
  }
}
