.ExerciseRomaji_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  .ExerciseRomaji_img {
    margin: 3rem auto;
    width: 45rem;
  }

  .ExerciseRomaji_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 100rem;
    margin: 5rem auto 0 auto;

    p {
      font-size: 3.6rem;
      margin-top: 1rem;
      margin-bottom: 0rem;
      color: #c80200;
    }

    input {
      max-width: 8rem;
      margin-top: 1rem;
      font-size: 2.4rem;
    }

    h4 {
      font-size: 1.4rem;
      color: #6c757d;

      &:hover,
      &:active {
        cursor: pointer;
        color: #2B2D42;
      }
    }

    .inputDefault {
      border: 1px solid #ced4da;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 15rem;
      width: 15rem;
      text-decoration: none;
      margin-bottom: 3rem;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
    }

    .inputOk {
      border: 2px solid #01750d;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 15rem;
      width: 15rem;
      text-decoration: none;
      margin-bottom: 3rem;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
    }

    .inputMistake {
      border: 2px solid #c80200;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 15rem;
      width: 15rem;
      text-decoration: none;
      margin-bottom: 3rem;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
    }
  }

  .ExerciseRomaji_newlist_buttom {
    font-size: 2.4rem;
    color: #c80200;
    text-decoration: none;
    display: flex;
    align-items: center;
    flex-direction: row;

    &:hover,
    &:active {
      color: #ef233c;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .ExerciseRomaji_container {
    align-items: center;
    justify-content: center;
    max-width: 90%;
    margin: 0 auto;
  }

  .ExerciseRomaji_img {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .ExerciseRomaji_box {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}
