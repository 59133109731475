.PageJLPTDetails_container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 140rem;
  margin: 0 auto;
  flex-direction: column;

  img {
    width: 140rem;
    margin-bottom: 2rem;
  }

  .PageJLPTDetails_box {
    width: 120rem;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5rem;
    margin: 0 auto;

    .PageJLPTDetails_gridItem {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 auto;
    }

    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    h1 {
      font-size: 4rem;
    }

    img {
      width: 52rem;
      border-radius: 5px;
    }

    h2 {
      font-size: 2.4rem;
    }

    .PageJLPTDetails_bigP {
      grid-column: span 2;
    }
  }

  @media (max-width: 768px) {
    .PageJLPTDetails_container {
      padding: 0 2rem;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    p {
      width: 100%;
    }

    .PageJLPTDetails_box {
      width: 85%;
      display: flex;
      align-items: center;
      flex-direction: column;
      grid-gap: 3rem;
      
    }

    .PageJLPTDetails_gridItem {
      
      text-align: center;
    }
  }
}
