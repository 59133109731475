.testDate_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 5rem;

  p {
    font-size: 2.4rem;
    color: #c80200;
    margin: 0 0 1rem 0rem;
  }

  @media (max-width: 768px) {
    margin: 1rem 0;
  }
  
  .testDate_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      font-size: 1.8rem;
      margin-bottom: 1rem;
      color: #8D99AE;
    }
  }
}
