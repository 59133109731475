.TestInformation_container {
  display: flex;
  align-items: center;
  max-width: 120rem;
  margin: 0 auto;
  flex-direction: column;

  img {
    width: 35rem;
    border-radius: 5px;
    border: 2px solid #c80200;
    margin-top: 3rem;
  }

  h2 {
    font-size: 4rem;
    margin: 6rem 0;
  }

  .TestInformation_box {
    width: 120rem;
    display: flex;
    align-items: start;
    flex-direction: column;
    margin: 0 auto;

    p {
      font-size: 1.6rem;
      line-height: 1.8rem;
      margin: 0;
      margin-bottom: 2rem;
    }

    h3 {
      font-size: 2.4rem;
      margin-bottom: 2rem;
    }

    h4 {
      font-size: 2rem;
      color: #c80200;
      margin-bottom: 1rem;
    }

    a {
      text-decoration: none;
      transition: all 0.3s;
      color: #2b2d42;
      font-size: 1.6rem;

      &:hover,
      &:active {
        color: #8d99ae;
      }
    }

    th {
      font-size: 1.6rem;
      line-height: 2.5rem;
      color: #c80200;
    }

    td {
      font-size: 1.6rem;
      line-height: 3rem;
      margin-bottom: 3rem;
      width: 25rem;
      border-bottom: 1px solid #8d99ae;
    }

    table {
      margin: 2rem 0;
    }

    .TestInformation_item {
      td {
        text-align: center;
      }
    }

    .TestInformation_time {
      width: 16rem;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    width: 90%;

    .TestInformation_box {
      width: 85%;
      th {
        font-size: 1.4rem;
      }
    }
  }
}
